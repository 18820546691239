// eslint-disable-next-line no-use-before-define
import React from 'react';
import './CustomError.scss';
import NoPermission from '@/components/no-permission/NoPermission';

interface errorProps {
    name: string;
    message: string;
    errorCode: string;
}

function CustomError(props: { error: errorProps }) {
  const { error } = props;
  if (error) {
    const { errorCode, name, message } = error;
    if (errorCode === 'access_denied') {
      return (
        <div className="main-app">
          <header className="main-app-header"></header>
          <NoPermission/>
        </div>
      );
    }
    return (
      <div>
        <p>{`${name}: ${message}`}</p>
      </div>
    );
  }
  return null;
}


export default CustomError;