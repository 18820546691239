import { configureStore } from '@reduxjs/toolkit';
import { reducer as userReducer } from '@/store/user';

const store = configureStore({
  reducer: {
    user: userReducer,
  },
  devTools: true
});

export default store;