import { Dropdown, MenuProps, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import HelpIcon from '@/assets/icons/help-icon.svg';
// eslint-disable-next-line no-use-before-define
import React from 'react';
import { helpItemConfigs } from '@/components/main-app-header/help-guide/HelpItemConfigs';
import './HelpGuide.scss';

const HelpGuide = (props: { popupContainer: () => HTMLElement }) => {
  const helpItems: MenuProps['items'] = helpItemConfigs.map((helpItemConfig,index) => ({
    key: index,
    label: ( helpItemConfig.href.startsWith('mailto')
      ? <a href={helpItemConfig.href}>
        {helpItemConfig.text}
      </a>
      : <a target="_blank" rel="noopener noreferrer" href={helpItemConfig.href}>
        {helpItemConfig.text}
      </a>
    )
  }));

  return <Tooltip title="Help">
    <Dropdown trigger={[ 'click' ]} menu={{ items: helpItems }} overlayClassName="help-dropdown-root"
      getPopupContainer={props.popupContainer}>
      <Icon className="help-dropdown-icon" component={HelpIcon} aria-label="Click on the help icon for product help"/>
    </Dropdown>
  </Tooltip>;
};

export default HelpGuide;
