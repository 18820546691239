import { get, isString } from 'lodash-es';

export const TOKEN_INFO_KEY = 'okta-token-storage';

export const localStorageUtils = {
  getItem: (key: string, parseValue = false) => {
    const value = localStorage.getItem(key);
    if (parseValue) {
      try {
        return JSON.parse(value || '');
      } catch (e) {
        return value;
      }
    } else {
      return value;
    }
  },
  setItem: (key: string, value: string | object) => {
    const localValue = isString(value) ? value : JSON.stringify(value);
    localStorage.setItem(key, localValue);
  },
  removeItem: (key: string) => {
    localStorage.removeItem(key);
  },
  clear: () => {
    localStorage.clear();
  }
};

export const getToken = () => {
  return  get(localStorageUtils.getItem(TOKEN_INFO_KEY, true), 'accessToken.accessToken', '');
};
