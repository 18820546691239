import { redirected } from '@/utils/pageUtils';
import { apps } from '@/index';
// eslint-disable-next-line no-use-before-define
import React from 'react';
import classNames from 'classnames';
import './MainAppHeader.scss';
import { MenuProps } from 'antd';
import HelpGuide from '@/components/main-app-header/help-guide/HelpGuide';
import DropdownMenu from '@/components/main-app-header/drop-down-menu/DropdownMenu';
import { useSelector } from 'react-redux';
import { selectPermissions } from '@/store/user';

interface MainAppHeaderProps {
    activeUrl: string;
    userName?: string;
}

const MainAppHeader = ({ activeUrl, userName } : MainAppHeaderProps) => {
  const permissions = new Map(Object.entries(useSelector(selectPermissions)));
  const appsWithPermission = apps.filter(subAppItem => permissions.get(subAppItem.accessPermissionName));
  const items: MenuProps['items'] = appsWithPermission.map(subApp => ({ key: subApp.activeRule, label: subApp.name }));

  const getMenuClassName = activeRule => {
    return classNames('main-app-header-side-menu-sub', {
      'main-app-header-side-menu-sub-active': activeUrl === activeRule,
    });
  };

  return (
    <header className="main-app-header" id="main-app-header">
      <div className="main-app-header-wrapper">
        <div className="main-app-header-app-name-wrapper" tabIndex={0} onClick={() => redirected('/')}>
          <i className="ri-dashboard-line main-app-header-icon" tabIndex={0}></i>
          <span className="main-app-header-app-name">Hive</span>
        </div>
        <div className="main-app-menu">
          {appsWithPermission.map(subAppItem => (
            <span key={subAppItem.name}
              className={getMenuClassName(subAppItem.activeRule)}
              onClick={() => redirected(subAppItem.activeRule)}>
              {subAppItem.name}
            </span>))}
        </div>
      </div>
      <div className="main-app-dropdown-wrapper">
        {activeUrl !== '/no-permission' && <DropdownMenu items={items} popupContainer={triggerNode => triggerNode}/>}
        {activeUrl === '/e-sign' && <HelpGuide popupContainer={() => document.getElementById('main-app-header')}/>}
        {userName && <span className="main-app-header-user-name">Hi, {userName}</span>}
      </div>
    </header>
  );
};
export default MainAppHeader;