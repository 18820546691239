import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Dropdown, MenuProps } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
// eslint-disable-next-line no-use-before-define
import React from 'react';
import { redirected } from '@/utils/pageUtils';
import './DropdownMenu.scss';

const DropdownMenu = (props: { items: ItemType[], popupContainer: (triggerNode: HTMLElement) => HTMLElement }) => {
  const onClick: MenuProps['onClick'] = ({ key }) => redirected(key);

  return <div className="main-app-dropdown-menu">
    <span className="main-app-dropdown-font">Menu</span>
    <Dropdown className="main-app-dropdown-icon" menu={{ items: props.items, onClick }}
      getPopupContainer={props.popupContainer}>
      <MenuOutlined data-testid="menu-out-lined"/>
    </Dropdown>
  </div>;
};

export default DropdownMenu;