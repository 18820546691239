// eslint-disable-next-line no-use-before-define
import React from 'react';
import { subAppNavigationItems } from '@/components/sub-app-navigation-bar/SubAppNavigationConfig';
import { redirected } from '@/utils/pageUtils';
import './SubAppNavigationBar.scss';
import { useSelector } from 'react-redux';
import { selectPermissions } from '@/store/user';

const SubAppNavigationBar = () => {
  const permissions = new Map(Object.entries(useSelector(selectPermissions)));

  return (
    <div className="sub-app-navigation-bar-container">
      {
        subAppNavigationItems
          .filter(subAppItem => permissions.get(subAppItem.accessPermissionName))
          .map(item =>{
            return (<div key={item.name} className={`sub-app-navigation-bar-container-item ${item.name.toLowerCase()}`}>
              <div className="sub-app-navigation-image"
                style={{
                  backgroundImage: `url(${item.backgroundImage})`
                }}></div>
              <div className="sub-app-navigation-bar-entry">
                <img data-testid={item.name} src={item.logoImage} alt="" className="sub-app-navigation-bar-logo" tabIndex={0}
                  onClick = {() => redirected(item.redirectedUrl)}></img>
                <i className="ri-arrow-right-s-line" tabIndex={0} data-testid="arrow-right-click-icon"
                  onClick = {() => redirected(item.redirectedUrl)}></i>
              </div>
            </div>);
          }
          )
      }
    </div>
  );
};

export default SubAppNavigationBar;
