// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import './Layout.scss';
import 'remixicon/fonts/remixicon.css';
import HomePage from '@/pages/home-page/HomePage';
import { apps } from '@/index';
import { redirected } from '@/utils/pageUtils';
import MainAppFooter from '@/components/main-app-footer/MainAppFooter';
import MainAppHeader from '@/components/main-app-header/MainAppHeader';
import { useDispatch, useSelector } from 'react-redux';
import { selectPermissions, setPermissions } from '@/store/user';
import { getPermissions } from '@/apis/permissionsApi';
import { AppDispatch } from '@/models/store';
import { useOktaAuth } from '@okta/okta-react';
import { startConfigs } from '@/qiankunConfig';
import { start } from 'qiankun';

function Layout() {
  const { oktaAuth } = useOktaAuth();
  const [ userInfo, setUserInfo ] = useState(null);
  const [ activeUrl, setActiveUrl ] = useState(null);
  const dispatch = useDispatch<AppDispatch>();
  const storedPermissions = useSelector(selectPermissions);
  const permissions = new Map(Object.entries(storedPermissions));
  const [ hasPermission, setHasPermission ] = useState(false);

  useEffect(() => {
    oktaAuth.isAuthenticated()
      .then(ret => {
        if (ret) {
          return oktaAuth.getUser();
        }
        return Promise.reject();
      })
      .then(info => {
        setUserInfo(info);
        start(startConfigs);
        window.history.pushState(null, '', location.pathname);
      }).then(() => {
        getPermissions().then(permissions => {
          dispatch(setPermissions(permissions));
        });
      }).catch(() => {
        oktaAuth.signInWithRedirect();
      });
  }, [ oktaAuth ]);

  useEffect(() => {
    const path = window?.location?.pathname;
    const activeMenu = apps.find(item => path?.includes(item.activeRule));
    if (activeMenu) {
      setActiveUrl(activeMenu?.activeRule);
    }
  }, []);

  useEffect(() => {
    const activePermissionName = apps.filter(subAppItem => activeUrl === subAppItem.activeRule)[0]?.accessPermissionName;
    if (activePermissionName) {
      if (!permissions.get(activePermissionName)) {
        setHasPermission(false);
        redirected('/no-permission');
      } else {
        setHasPermission(true);
      }
    }
  },[ storedPermissions ]);

  return (userInfo && (
    <div className="main-app">
      <MainAppHeader activeUrl={activeUrl} userName={userInfo.name}/>
      {
        activeUrl === null
          ? <HomePage/>
          : <div id="subapp-viewport" style={{ visibility: hasPermission ? 'visible' : 'hidden' }}></div>
      }
      <MainAppFooter />
    </div>
  ));
}

export default Layout;
