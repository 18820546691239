import LogoImage from '@/assets/main-app-tw-logo.webp';
// eslint-disable-next-line no-use-before-define
import React from 'react';
import './MainAppFooter.scss';
const MainAppFooter = () => {
  return (
    <div className="main-app-footer">
      <div className="main-app-footer-introduce-wrapper">
        <div className="main-app-footer-content">
                  Need more information?
          <a href="mailto:china-regional-it@thoughtworks.com ">Contact us</a>
                  or
          <a href="https://central.thoughtworks.net/home/techops-regional-it-china" target="_blank"
            rel="noreferrer">
                      Check Regional IT China Central
          </a>
        </div>
        <img src={LogoImage} alt=""></img>
      </div>
    </div>
  );
};
export default MainAppFooter;