// eslint-disable-next-line no-use-before-define
import React from 'react';
import 'remixicon/fonts/remixicon.css';
import NoPermission from '@/components/no-permission/NoPermission';
import MainAppFooter from '@/components/main-app-footer/MainAppFooter';
import MainAppHeader from '@/components/main-app-header/MainAppHeader';

const NoPermissionPage = () => {
  return (
    <div className="main-app">
      <MainAppHeader activeUrl={'/no-permission'}/>
      <NoPermission/>
      <MainAppFooter/>
    </div>
  );
};

export default NoPermissionPage;