import EasySignPic from '@/assets/sub-app-easy-sign-pic.jpg';
import EasySignLogo from '@/assets/sub-app-easy-sign-logo.webp';
import BillSpiltPic from '@/assets/sub-app-bill-split-pic.jpg';
import BillSpiltLogo from '@/assets/sub-app-bill-split-logo.webp';
import TimeCardReminderPic from '@/assets/sub-app-timecard-reminder-pic.jpg';
import TimeCardReminderLogo from '@/assets/sub-app-timecard-reminder-logo.png';

export interface SubAppNavigationConfig {
    name: string
    backgroundImage: string
    logoImage: string
    redirectedUrl: string
    accessPermissionName: string
}

export const subAppNavigationItems: SubAppNavigationConfig[] = [
  {
    name: 'EasySign',
    backgroundImage: EasySignPic,
    logoImage: EasySignLogo,
    redirectedUrl: '/e-sign',
    accessPermissionName: 'e-sign-access'
  },
  {
    name: 'BillSplit',
    backgroundImage: BillSpiltPic,
    logoImage: BillSpiltLogo,
    redirectedUrl: '/bill-split',
    accessPermissionName: 'bill-split-access'
  },
  {
    name: 'TimecardReminder',
    backgroundImage: TimeCardReminderPic,
    logoImage: TimeCardReminderLogo,
    redirectedUrl: '/timecard-reminder',
    accessPermissionName: 'timecard-reminder-access'
  }
];
