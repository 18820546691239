// eslint-disable-next-line no-use-before-define
import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { LoginCallback, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import './App.scss';
import { eventBus } from './utils/eventBus';
import { message } from 'antd';
import CustomError from '@/components/custom-error/CustomError';
import Layout from '@/components/layout/Layout';
import NoPermissionPage from '@/pages/no-permission-page/NoPermissionPage';

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_E_SIGN_OKTA_ISSUER,
  clientId: process.env.REACT_APP_E_SIGN_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
});

interface AppProps {
  loading?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const App = (props: AppProps) => {
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  useEffect(() => {
    eventBus.on('unauthorized', () => {
      oktaAuth.isAuthenticated()
        .then(ret => {
          if (ret) {
            message.info('操作失败，请重试');
          } else {
            oktaAuth.signInWithRedirect();
          }
        });
    });
    return () => {
      eventBus.off('unauthorized');
    };
  }, []);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route path="/" element={<Layout />} >
          <Route path="/e-sign/" element={<></>}>
            <Route path="*" element={<></>} />
          </Route>
          <Route path="/bill-split/" element={<></>}>
            <Route path="*" element={<></>} />
          </Route>
          <Route path="/timecard-reminder/" element={<></>}>
            <Route path="*" element={<></>} />
          </Route>
          <Route path="/e-sign/no-permission" element={<></>} />
        </Route>
        <Route path="/login/callback" element={<LoginCallback errorComponent={CustomError} />} />
        <Route path="/no-permission" element={<NoPermissionPage />} />
      </Routes>
    </Security>
  );
};

export default App;
