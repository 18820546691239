import './NoPermission.scss';
import NoPermissionImage from '@/assets/no-permission.svg';
const NoPermission = () => {
  return (
    <div className="no-permission-align-center ">
      <img className="no-permission-image" src={NoPermissionImage} alt="Sorry, you do not have permission to access this page"/>
      <div className="no-permission-hint">
        <span>抱歉，你无权访问该页面</span>
        <span>
            如有问题，请联系 <span className="hr-email">china-regional-it@thoughtworks.com</span>
        </span>
      </div>
    </div>
  );
};

export default NoPermission;