// eslint-disable-next-line max-len
const EASY_SIGN_USER_MANUAL_LINK = 'https://docs.google.com/presentation/d/1804PGz_6HZiNy7w7w3DCzsSu27ZldCux2tQrOJ_SOmQ/edit#slide=id.g238c9dd0197_0_665';
const PEOPLE_SUPPORT_EMAIL_ADDRESS = 'mailto:peoplesupport-china@thoughtworks.com';
const LEGAL_VALIDITY_OF_ELECTRONIC_SIGNATURE = 'https://docs.google.com/document/d/17wWdRvYmQIw_DV22mcp8YTfd1oFn_1BbRwD06cPsYtc/edit';
export const helpItemConfigs = [ {
  href: EASY_SIGN_USER_MANUAL_LINK,
  text: 'EasySign 用户手册'
}, {
  href: PEOPLE_SUPPORT_EMAIL_ADDRESS,
  text: '联系People Support Team'
}, {
  href: LEGAL_VALIDITY_OF_ELECTRONIC_SIGNATURE,
  text: '《电子签名的法律有效性说明》'
} ];