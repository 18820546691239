type IHandler = (...args) => void;

class EventBus {
  private eventHandlers: Map<string, IHandler[]> = new Map();

  on(eventType: string, newHandler: IHandler) {
    const handlers = this.eventHandlers.get(eventType);
    if (!handlers) {
      this.eventHandlers.set(eventType, [ newHandler ]);
    } else {
      handlers.push(newHandler);
    }
  }

  once(eventType: string, newHandler: IHandler) {
    const handler = (...args) => {
      this.off(eventType, handler);
      newHandler.apply(this, args);
    };
    this.on(eventType, handler);
  }

  emit(eventType: string, ...args) {
    const handlers = this.eventHandlers.get(eventType);
    handlers.forEach(handler => {
      handler.apply(this, args);
    });
  }

  off(eventType: string, handler?: IHandler) {
    if (!handler) {
      this.eventHandlers.set(eventType, []);
      return;
    }
    const handlers = this.eventHandlers.get(eventType);
    const handlerIndex = handlers.findIndex(fn => fn === handler);
    if (handlerIndex !== -1) {
      handlers.splice(handlerIndex, 1);
    }
  }
}

export const eventBus = new EventBus();