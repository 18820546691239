import { createSlice } from '@reduxjs/toolkit';
import { StoreState } from '@/models/store';

const initialState = {
  permissions: {}
};

const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    }
  }
});

export const selectPermissions = (state: StoreState) => state.user.permissions;

export const { reducer } = userReducer;

export const { setPermissions } = userReducer.actions;