import Banner from '../../assets/main-app-banner.jpg';
// eslint-disable-next-line no-use-before-define
import React from 'react';
import './HomePage.scss';
import SubAppNavigationBar from '@/components/sub-app-navigation-bar/SubAppNavigationBar';

const HomePage = () => {
  return (
    <div className="main-app-wrapper">
      <div className="main-app-banner" style={{
        backgroundImage: `url(${Banner})`
      }}></div>
      <div className="main-app-introduce">
        <p>
          Hive是一个数字化体验平台，致力于中国区的运营效率提升、降低成本，提升中国员工的数字化用户体验。
        </p>
        <p>
          Hive is a digital experience platform that is committed to helping the operation in China to increase efficiency and reduce costs,
          and improves the digital user experience of Chinese employees.
        </p>
      </div>
      <div className="sub-app-navigation-bar">
        <div className="sub-app-navigation-bar-title">
          <h1>Our Tools</h1>
        </div>
        <SubAppNavigationBar/>
      </div>
    </div>
  );
};

export default HomePage;
