import App from './App';
import { registerMicroApps } from 'qiankun';
import reportWebVitals from './reportWebVitals';
import ReactDOMClient from 'react-dom/client';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import { eventBus } from './utils/eventBus';
import { Provider } from 'react-redux';
import store from '@/store';

const container = document.getElementById('platform-root');
const root = ReactDOMClient.createRoot(container);

function render({ loading }) {
  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <App loading={loading}/>
      </Provider>
    </BrowserRouter>
  );
}

const loader = loading => render({ loading });

const microAppConfig = [
  {
    name: 'EasySign',
    entry: process.env.REACT_APP_E_SIGN_WEB,
    activeRule: '/e-sign',
    container: '#subapp-viewport',
    accessPermissionName: 'e-sign-access'
  },
  {
    name: 'BillSplit',
    entry: process.env.REACT_APP_BILL_SPLIT_WEB,
    activeRule: '/bill-split',
    container: '#subapp-viewport',
    accessPermissionName: 'bill-split-access'
  },
  {
    name: 'TimecardReminder',
    entry: process.env.REACT_APP_TIMECARD_REMINDER_WEB,
    activeRule: '/timecard-reminder',
    container: '#subapp-viewport',
    accessPermissionName: 'timecard-reminder-access'
  },
];

export const apps = microAppConfig.map(item => {
  return {
    ...item,
    props: {
      routerBase: item.activeRule,
      eventBus,
    },
  };
});

render({ loading: true });

const microApps = apps.map(app => ({
  ...app,
  loader,
}));

registerMicroApps(microApps);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
